$('.js-trim-select').on('change', function() {
  var value = $(this).val();
  var url = '/ajax/connector.cfc?method=setBodyType&strBodyType=' + value;
  $.ajax({
    method: 'POST',
    url: url
  }).done(function() {
    window.location.href = '/accessory-brands/';
  });
});

$('.js-filterCapType').change(function changeMake() {
  $('#accessory-search form').submit();
});


// upsell
$('#app-guide-kit-to-wheel').hide();
$('#upsell-close-popup').click(function(){
  //$('#cart-accessoryForm').submit();
  $('#app-guide-kit-to-wheel').fadeOut();
  $('.popup-content').show();

  $.magnificPopup.close();
});
$('#upsell-open-fitment').click(function(){
  $('#app-guide-kit-to-wheel').fadeIn();
  $('.popup-content').hide();
});

$('.upsell-wheels-open').click(function(){
  $.magnificPopup.open({
    items: {
      src: '#upsell-wheels-popup',
      type: 'inline'
    },
    callbacks: {
      close: function() {
        window.SnipCartSubmitAddToCart();
        $('#app-guide-kit-to-wheel').fadeOut();
        $('.popup-content').show();
        $('#upsell-close-popup').click(function(){
          $.magnificPopup.close();
        });
      },
    }
  });
});

$('#app-guide-kit-to-wheel .vehicle-models').change(function(){
  $('body').addClass('do-it');  
  //$('body').fadeOut();
  // $('#cart-accessoryForm').submit();
  window.SnipCartSubmitAddToCart();
});
$('#app-guide-kit-to-wheel #fitmentSubmit').click(function(){
  //console.log('me clicks');
  //$('body').addClass('do-it');
  //$('body').html('<div class="white text-center processing"><i class="fa fa-spinner fa-spin fa-4x"></i></div>')
  /*$.magnificPopup.open({
    items: {
      src: '<div class="white text-center processing"><i class="fa fa-spinner fa-spin fa-4x"></i></div>'
    },
    type: 'inline'
  });*/
  //$('#cart-accessoryForm').submit(function(){
  //  $('#app-guide-kit-to-wheel .vehicle-models').trigger('change');
  //});
  $('#app-guide-kit-to-wheel .vehicle-models').trigger('change');
});
/*
FC.client.on('cart-submit', function(params, next){
  // Check the product 
  console.log('is this work');
});*/
