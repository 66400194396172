// footer

/*$('.footer-button').click(function(){
  if ($(this).hasClass('footer-open')) {
    $(this).removeClass('footer-open');
    console.log('we got it');
    $('.footer-content').addClass('slideOutUp');
    $('.footer-content').removeClass('slideInDown');
  } else {
    $(this).addClass('footer-open');
    console.log('nope, no class at all');
    $('.footer-content').addClass('slideInDown');
    $('.footer-content').removeClass('slideOutUp');
  }
  
});*/


var ftBtn = document.getElementsByClassName("footer-button")[0];
var caretIcon = document.getElementById("caret-btn");
var ftContent = document.getElementsByClassName("footer-content")[0];
var refreshIntervalId;

if (typeof ftBtn !== 'undefined') {
  ftBtn.addEventListener("click", openFooter);
}
function openFooter() {
  
  this.classList.toggle("footer-open");
  
  if (ftContent.style.maxHeight){
    ftContent.style.maxHeight = null;
    caretIcon.classList.add('fa-caret-down');
    caretIcon.classList.remove('fa-caret-up');
    
  } else {
    ftContent.style.maxHeight = ftContent.scrollHeight + "px";
    caretIcon.classList.add('fa-caret-up');
    caretIcon.classList.remove('fa-caret-down');
    refreshIntervalId = setInterval(scrollToBottom, 500);
  }
}
var count = 0;


function scrollToBottom() {
  
  window.scrollTo(0,document.body.scrollHeight);
    clearInterval(refreshIntervalId);
  
  
 /* console.log('hey, im scrolling ere!');
  console.log(ftContent.style.maxHeight);
  console.log(ftContent.scrollHeight);*/
  
  
  
/*activeScrolling();*/
/*  window.scrollTo(0,document.body.scrollHeight);
    clearInterval(refreshIntervalId);*/
  
  /*console.log(className);
  var element = className;
   element.scrollTop = element.scrollHeight - element.clientHeight;*/
  /*ftContent.scrollTop = ftContent.scrollHeight - ftContent.clientHeight;*/
  /*$('.footer-content').animate({
      scrollTop: ftContent.scrollHeight - ftContent.clientHeight
   }, 500);*/
  /*var el = document.querySelector('.footer-content');
    smooth_scroll_to(el, el.scrollTop - 200, 600);
  console.log('is this even working?');*/
}

function activeScrolling() {
  if ((ftContent.scrollHeight + "px") == ftContent.style.maxHeight) {
      clearInterval(refreshIntervalId);
    } else {
      ftContent.scrollHeight++; 
      ftContent.style.top = ftContent.scrollHeight + 'px'; 
      ftContent.style.left = ftContent.scrollHeight + 'px'; 
    }
}

var smooth_scroll_to = function(element, target, duration) {
    target = Math.round(target);
    duration = Math.round(duration);
    if (duration < 0) {
        return Promise.reject("bad duration");
    }
    if (duration === 0) {
        element.scrollTop = target;
        return Promise.resolve();
    }

    var start_time = Date.now();
    var end_time = start_time + duration;

    var start_top = element.scrollTop;
    var distance = target - start_top;

    // based on http://en.wikipedia.org/wiki/Smoothstep
    var smooth_step = function(start, end, point) {
        if(point <= start) { return 0; }
        if(point >= end) { return 1; }
        var x = (point - start) / (end - start); // interpolation
        return x*x*(3 - 2*x);
    }

    return new Promise(function(resolve, reject) {
        // This is to keep track of where the element's scrollTop is
        // supposed to be, based on what we're doing
        var previous_top = element.scrollTop;

        // This is like a think function from a game loop
        var scroll_frame = function() {
            if(element.scrollTop != previous_top) {
                reject("interrupted");
                return;
            }

            // set the scrollTop for this frame
            var now = Date.now();
            var point = smooth_step(start_time, end_time, now);
            var frameTop = Math.round(start_top + (distance * point));
            element.scrollTop = frameTop;

            // check if we're done!
            if(now >= end_time) {
                resolve();
                return;
            }

            // If we were supposed to scroll but didn't, then we
            // probably hit the limit, so consider it done; not
            // interrupted.
            if(element.scrollTop === previous_top
                && element.scrollTop !== frameTop) {
                resolve();
                return;
            }
            previous_top = element.scrollTop;

            // schedule next frame for execution
            setTimeout(scroll_frame, 0);
        }

        // boostrap the animation process
        setTimeout(scroll_frame, 0);
    });
}