// details pages (accessory / wheels)

if($('.inventory-detail').length) {
  console.log('hello')
  ///// Button functions /////
  // execute add to cart popup
  $('.buy-link').click(function(e){
    let strPartnumber = $(this).attr('data-part')
    $('#part').text(strPartnumber);
    $('#part').attr('data-part',strPartnumber);
    let strProduct = $(this).attr('data-product')
    $('#product').text(strProduct);
    let strArticleNumber = $(this).attr('data-article')
    $('#article').text(strArticleNumber);
    let strType = $(this).attr('data-type')
    let decPrc = $(this).attr('data-price') 
    let strPrc = $(this).attr('data-price-string') 
    $('#price').text(strPrc);
    $('#price').attr('data-price',decPrc);
    let strSpec = $(this).attr('data-spec') 
    $('#specs').text(strSpec);
    $('#specs').attr('data-spec',strSpec);
    $("#ware-box")
        .css({"display":"flex"})
        .animate({
          opacity: 1
    });	
  });

  
  // Update Quantity
  /*
  $( "#qty" ).change(function() {
    var max = parseInt($(this).attr('max'));
    var min = parseInt($(this).attr('min'));
    if ($(this).val() > max) {
      $(this).val(max);
    } else if ($(this).val() < min) {
      $(this).val(min);
    }       
  });*/

  
  
  // Add item to cart
  $('#buy').click(function(e){
    let strQty= $('#qty').val();
    let strPrice=$('#price').attr('data-price');
    let strPartnumber = $('#part').attr('data-part');
    let strSpec = $('#specs').attr('data-spec');

    console.log(strPrice);
  
    var dataString = 'price=' + strPrice +'&part=' + strPartnumber +'&qty='+ strQty + /*'&qtyavail='+ strQtyAvail +*/ '&spec='+ strSpec;
    $.ajax({  
      type: "POST",  
      url: "/inventory/ajax/addtocart.cfm",  
      data: dataString,  
      success: function(data) {	
        if ($.trim(data)  =='expired'){
          window.location.reload();
        } else {
          $('#message').html(data);
          $("#ware-box").animate({
            opacity:0
          },
          function(){
            $(this).hide();
          });			
          $("#ware-box2")
            .css({"display":"flex"})
            .animate({
              opacity: 1
            });
          }
        }
    });  		 					 
  });


  // Close Add to Cart Popup
$('#cancel').click(function(e){
    $("#ware-box").animate({
        opacity:0
      },
      function(){
        $(this).hide();
      });					 
});


  // Close notification popup
$('#close').click(function(e){
    $("#ware-box2").animate({
        opacity:0
       },
      function(){
         $(this).hide();
      });							 
});
  
  ///// Cart Popup /////
  // view cart
  $('#viewCart').click(function(e){
    $.ajax({  
      type: "POST",  
      url: "ajax/viewCart.cfm",  
      success: function(data) {	
        if ($.trim(data)  =='expired'){
          window.location.reload();
        } else {
          $('#ware-box3').html(data);
          $("#ware-box2").animate({
            opacity:0
          },
          function(){
            $(this).hide();
          });			
          $("#ware-box3")
            .css({"display":"flex"})
            .animate({
              opacity: 1
            });
          }
        }
    });  		 					 
  });  
  
};
