/* global $, Foundation, MotionUI, List */
/* ==========================================================================
   Table of Contents
   ========================================================================== */

/*
    1.   General
    ---  Product Pages  ---
    2.1  Brand Pages
    2.2  Detail Pages
    ---  Gallery Pages  ---
    3.1  Gallery Landing Page
    3.3  Gallery Detail Page
    ---  Other Pages  ---
    4.1  Home Page
    4.2  Contact Page
    4.3  Article Page
*/
/* ==========================================================================
1. General
========================================================================== */
$(document).foundation();

var $img = $('.parallax .img');
var $heading = $('.parallax h1');
var scroller;
var wh = $(window).height();

$('#fitmentSubmit').on('click', function submitFitment() {
  var size = $('#size');
  if (size.val() && size.val() !== 'Select Size') {
    size.change();
  }
});

$('#app-guide-header-desktop .vehicle-submodels, #app-guide-header-mobile .vehicle-submodels').on('change', function submitFitment() {
  window.location = "/products/";
});

$('#app-guide-promotion-page .vehicle-submodels, #app-guide-header-mobile .vehicle-submodels').on('change', function submitFitment() {
  window.location = "/march-madness/";
});

// Mobile Accordion
if ($('.accordion').length && Foundation.MediaQuery.atLeast('medium')) {
  $('.accordion').each(function openAccordion() {
    $(this).foundation(
      'down',
      $(this)
        .find('.accordion-content')
        .first(),
      true
    );
  });
}

// Scroll to section
$('.scroll-to').on('click', function scrollToElem(event) {
  var target = $($(this).attr('href'));
  if (target.length) {
    event.preventDefault();
    $('html, body').animate(
      {
        scrollTop: target.offset().top
      },
      1000
    );
  }
});

// Scroll to section - Catalogs
$('.catalog-scroll-to').on('click', function scrollToElem(event) {
  var target = $($(this).attr('href'));
  if (target.length) {
    event.preventDefault();
    $('html, body').animate(
      {
        scrollTop: target.offset().top - 260
      },
      1000
    );
  }
});


// Varify captcha
$('.rfqSubmit, .check-captcha').on('click', function() {
  if (grecaptcha.getResponse() === '') {
    var response = document.getElementById('check-captcha');
    response.innerHTML = 'Captcha box not selected';
    return false;
  } else {
    return true;
  }
});

//Show/Hide navigation search box form
var searchBoxFlag = {
  active: false
};

$(document).click(function(e) {
  closeSearchBox(e);
});

function closeSearchBox(e) {
  var target = $('.nav-search');

  if (!searchBoxFlag.active) {
    return;
  }

  if (!target.is(e.target) && target.has(e.target).length === 0) {
    $('.js-search-box').slideUp();
    $('.js-overlay-nav').addClass('hidden');
    searchBoxFlag.active = false;
  }
}

// search menu link slide toggle
$('.js-search-link').on('click', function(e) {
  e.preventDefault();
  $('.js-search-box').slideToggle();
  $('.js-overlay-nav').toggleClass('hidden');
  searchBoxFlag.active = !searchBoxFlag.active;
});

$('.js-search-btn').magnificPopup({
  items: {
    src:
      '<div class="white text-center processing">' +
      '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>' +
      '</div>',
    type: 'inline',
    modal: true
  },
  callbacks: {
    open: function() {
      $('#search-form').submit();
    }
  }
});

// holiday 2022
/*
const giftGuideBtn = document.getElementById("getGiftGuideOpen");

function giftGuideOpen() {
  //console.log('i am opening');
  window.open("https://iconvehicledynamics.com/giftguide/", "_giftGuide");
  //window.open("http://iconvehicledynamics.local/giftguide/", "_blank");
}

giftGuideBtn.addEventListener("click", () => {
  //console.log('it is my purpose');
  giftGuideOpen();
});
if(!$('#giftguide').length) {
  //console.log('clicky time');
  //setTimeout(giftGuideBtn.dispatchEvent(new Event('click')),5000);
  setTimeout(function(){
    //console.log('its time')
    giftGuideBtn.dispatchEvent(new Event('click'))
  },5000)
}*/

// this actually pops up
/*
const pop = (url, w, h) => {
  const popup = window.open(url, '_blank', 'toolbar=0,location=0,directories=0,status=1,menubar=0,titlebar=0,scrollbars=1,resizable=1,width=1200,height=1200');
  return popup !== null && typeof popup !== 'undefined'
}
console.log(pop('https://iconvehicledynamics.com/giftguide/'))
*/
/* ==========================================================================
     2.1 Brand Pages
     ========================================================================== */

// Function for Wheel Filter to change page to 1 when a brand is selected
// on a page greater than 1
function replaceUrlParam(url, key, value) {
  if (value === null) value = '';
  var pattern = new RegExp('\\b(' + key + '=).*?(&|$)');
  if (url.search(pattern) >= 0) {
    return url.replace(pattern, '$1' + value + '$2');
  }
  return url + (url.indexOf('?') > 0 ? '&' : '?') + key + '=' + value;
}

// Wheel filters
$('.js-finish-filter, .js-brand-filter, #tireFilters').on(
  'change',
  'select',
  function(event) {
    event.preventDefault();
    // Get type of filter and current query
    var $this = $(this);
    var type = $this.attr('name');
    var query = window.location.search;
    var urlSection = '';
    // Maintain BrandID and BrandName
    var brandID; // Use this method if still using web.config to rename url string to friendly urls
    var title = $this.find('option:selected').text();
    title = title.replace(/\s+/g, '-').toLowerCase();

    var brandTitle;
    var wheelMatch = window.location.href.match('wheels/' + /\d+/) > -1;

    // If this filter has already been applied, replace
    if (query.indexOf(type + '=') > -1) {
      var regex = new RegExp('(' + type + '=)([^&]+)?', 'ig');
      query = query.replace(regex, '$1' + $this.val());
      // If there are already other params
    } else if (query.indexOf('?') > -1) {
      if (type == 'brand' && wheelMatch) {
        // set brandID variable
        brandID = $this.val();
        brandTitle = title;
      } else {
        query += '&' + type + '=' + $this.val();
      }
      // If this is the only param
    } else {
      if (type == 'brand' && wheelMatch) {
        // set brandID variable
        brandID = $this.val();
        brandTitle = title;
      } else {
        query += '?' + type + '=' + $this.val();
      }
    }
    // Set the page to 1
    if (window.location.href.match('page')) {
      query = replaceUrlParam(query, 'page', 1);
    }
    if ($this.parent().attr('id') == 'tireFilters') {
      if (window.location.href.match('tires/' + /\d+/) > -1) {
      }
    } else if (window.location.href.match('wheels/' + /\d+/) > -1) {
      var urlSplit = document.location.href.split('/');
      if (typeof brandID !== 'undefined') {
        // replaces the brandID in url string
        urlSection = brandID;
      } else {
        urlSection = urlSplit[4];
        brandTitle = urlSplit[5];
      }
      if (urlSection > 0) {
        window.location.href =
          '/wheels/' + urlSection + '/' + brandTitle + '/' + query;
      } else {
        window.location.href = '/wheels/' + query;
      }
    } else {
      window.location.href = window.location.pathname + query;
    }
  }
);

// PARALLAX EFFECT   http://codepen.io/sallar/pen/lobfp
// requestAnimationFrame Shim
window.requestAnimFrame = (function animFramPoly() {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function animTimout(callback) {
      window.setTimeout(callback, 1000 / 60);
    }
  );
})();

// Scroller
function Scroller() {
  this.latestKnownScrollY = 0;
  this.ticking = false;
}

Scroller.prototype = {
  // Initialize
  init: function init() {
    window.addEventListener('scroll', this.onScroll.bind(this), false);
  },

  // Capture Scroll
  onScroll: function onScroll() {
    this.latestKnownScrollY = window.scrollY;
    this.requestTick();
  },

  // Request a Tick
  requestTick: function requestTick() {
    if (!this.ticking) {
      window.requestAnimFrame(this.update.bind(this));
    }
    this.ticking = true;
  },

  // Update
  update: function update() {
    var currentScrollY = this.latestKnownScrollY;

    // Do The Dirty Work Here
    var imgScroll = currentScrollY / 2;
    var headScroll = currentScrollY / 3;

    this.ticking = false;

    $img.css({
      transform: 'translateY(' + imgScroll + 'px)',
      '-moz-transform': 'translateY(' + imgScroll + 'px)',
      '-webkit-transform': 'translateY(' + imgScroll + 'px)'
    });

    $heading.css({
      transform: 'translateY(' + headScroll + 'px)',
      '-moz-transform': 'translateY(' + headScroll + 'px)',
      '-webkit-transform': 'translateY(' + headScroll + 'px)'
    });
  }
};

// Attach!
if ($('.parallax .img').length || $('.parallax h1').length) {
  scroller = new Scroller();
  scroller.init();
}

// Insert vehicle image into vehicle bar
function insertVehicleImage() {
  var vehicleData = $('.vehicleData').data();
  var getVehicle = '//iconfigurators.app/api/?function=getVehiclePreview';
  getVehicle += '&year=' + vehicleData.year;
  getVehicle += '&make=' + vehicleData.make;
  getVehicle += '&model=' + vehicleData.model;
  getVehicle += '&submodel=' + vehicleData.submodel;
  getVehicle += '&option=' + vehicleData.option;
  getVehicle += '&key=' + vehicleData.key;
  $.getJSON(getVehicle, function(data) {
    if (data.vehicles[0] !== undefined) {
      var returnedImgUrl = data.vehicles[0].previewImage;
      var returnedImg = '<img ';
      returnedImg += 'src="' + returnedImgUrl + '"';
      returnedImg +=
        'alt="' +
        vehicleData.year +
        ' ' +
        vehicleData.make +
        ' ' +
        vehicleData.model +
        '"';
      returnedImg += '/>';
      $('.vehicleData').append(returnedImg);
    }
  });
}

if ($('.setVehicleImg').length > 0) {
  insertVehicleImage();
}

/* ==========================================================================
     WHEEL FILTERS
     ========================================================================== */
if ($('.wheel-filter').length) {
  // Disable 'width' when no diameter is available
  $('#wheel-diam').on('change', function() {
    if ($(this).val() !== '') {
      $('#wheel-width').prop('disabled', false);
    } else {
      $('#wheel-width').prop('disabled', true);
    }
  });

  $('.wheel-filter select').on('change', function() {
    $('.js-show-search').removeClass('hidden');
  });

  $('.wheel-filter').on('submit', function(event) {
    event.preventDefault();

    $.magnificPopup.open({
      items: {
        src:
          '<div class="white text-center processing">' +
          '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>' +
          '</div>'
      },
      closeBtnInside: false
    });

    var filterURL = $('.wheel-filter :input')
      .filter(function(index, element) {
        return $(element).val() !== '';
      })
      .serialize();

    window.location.href = '/wheels/?' + filterURL;
  });
}

// specials/default.cfm
if($('#wheels.wheel-specials')) {
  $('#wheel-diam').on('change', function() {
    if ($(this).val() !== '') {
      $('#wheel-width').prop('disabled', false);
    } else {
      $('#wheel-width').prop('disabled', true);
    }
  });

  $('.wheel-filter select').on('change', function() {
    $('.js-show-search').removeClass('hidden');
  });

  $('.wheel-filter').on('submit', function(event) {
    event.preventDefault();

    $.magnificPopup.open({
      items: {
        src:
          '<div class="white text-center processing">' +
          '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>' +
          '</div>'
      },
      closeBtnInside: false
    });

    var filterURL = $('.wheel-filter :input')
      .filter(function(index, element) {
        return $(element).val() !== '';
      })
      .serialize();

    window.location.href = '/specials/?' + filterURL;
  });
}
/* ==========================================================================
     2.2 Detail Pages
     ========================================================================== */
// Slider and popup for main image
function initializeMainImage() {
  $('.main-image')
    .magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1],
        dots: false
      }
    })
    .slick({
      arrows: false,
      dots:true,
      //asNavFor: '.alt-images',
      fade: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true
    });

  $('.alt-images').slick({
    arrows: false,
    asNavFor: '.main-image',
    dots: false,
    focusOnSelect: true,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive:[
      {
        breakpoint:600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      }
    ]
  });
}

initializeMainImage();

if ($('.slick-dots').length) {
  var $dots = 0
  $('.slick-dots li').each(function(){
    $dots++;
    console.log($dots);
  });
  if ($dots < 2) {
    $('.slick-dots').css('display','none');
  }
}

// Gallery View Popup
$('.galleryView-open').on('click', function() {
  for (var dataName in $(this).data()) {
    if ($(this).data()[dataName] === '') {
      continue;
    }

    if (dataName === 'wheelimage') {
      $('#galleryView-popup img').attr('src', $(this).data()[dataName]);
    }

    var htmlString = '';
    htmlString = dataName;
    htmlString += ': ';
    htmlString += $(this).data()[dataName];
    htmlString = htmlString.replace(/car: /, '').replace(/size/, ' size');
    $('.galleryView-info')
      .find('.popup-' + dataName)
      .html(htmlString);
  }
  $.magnificPopup.open({
    items: {
      src: '#galleryView-popup',
      type: 'inline'
    }
  });
});

// Show/Hide excess paragraph lines
function getTextHeight(selector) {
  var height = parseFloat($(selector).height());
  var lineHeight = parseFloat($(selector).css('line-height'));
  return Math.round(height / lineHeight);
}

// Show/Hide excess information
if ($('.info--limit').length) {
  if (getTextHeight('.info--limit') > 5) {
    $('.info--limit').addClass('info--height');
    $('.info-show').removeClass('hidden');
  }
}

if ($('.wheel-galleryList').length) {
  if ($('.wheel-galleryList__item').length > 6) {
    $('.wg-Show').removeClass('hidden');
  }
}

function toggleButtons(classSelect, cssSelect, toggleShowBtn, toggleHideBtn) {
  $(classSelect).toggleClass(cssSelect);
  $(toggleShowBtn).toggleClass('hidden');
  $(toggleHideBtn).toggleClass('hidden');
}

$('.info-show, .info-hide').on('click', function toggleParagraph() {
  toggleButtons('.info--limit', 'info--height', '.info-show', '.info-hide');
});
$('.wg-Show, .wg-Hide').on('click', function toggleGallery() {
  toggleButtons(
    '.wheel-galleryList__item',
    'wheel-galleryList__item--display',
    '.wg-Show',
    '.wg-Hide'
  );
});

function toggleBoltPatStringSize(type, event) {
  var bpList = $('.js-bpStringLength').data('list');
  var bpArray = bpList.split(',');

  if (event === undefined) {
    return;
  }

  if (type !== 'more' && bpArray.length > 5) {
    bpList = bpArray.slice(0, 5);
  }
  if (event.type === 'click') {
    $('.js-bpStringLength')
      .siblings('.js-show-less')
      .toggleClass('hidden');
    $('.js-bpStringLength')
      .siblings('.js-show-more')
      .toggleClass('hidden');
  } else {
    $('.js-bpStringLength')
      .siblings('.js-show-more')
      .removeClass('hidden');
  }

  $('.js-bpStringLength').text('Custom Drilled ' + bpList);
}

if ($('.js-bpStringLength').length) {
  toggleBoltPatStringSize('default', '');
  $('.js-show-more').on('click', function() {
    toggleBoltPatStringSize('more', event);
  });
  $('.js-show-less').on('click', function() {
    toggleBoltPatStringSize('less', event);
  });
}

// Get see on vehicle image
function detailCarImage(
  car,
  year,
  make,
  model,
  submodel,
  wheel,
  color,
  body,
  configid,
  option
) {
  try {
    $.getJSON(
      'http://ver1.iconfigurators.app/ap-json/ap-image-ID-color.aspx' +
        `?year=${year}` +
        `&make=${make}` +
        `&model=${model}` +
        `&submodel=${submodel}` +
        `&wheel=${wheel}` +
        `&color=${color}` +
        `&body=${body}` +
        `&configid=${configid}` +
        `&option=${option}`,
      function getCarImage(data) {
        if (data.Result > 0) {
          car.find('img').attr('src', data.img[0].src);

          $('#vehicle-colors').html('');
          var colorIds = data.img[0].colorID;
          var colorNames = data.img[0].colorName;

          for (var index = 0; index < colorIds.length; index++) {
            if (colorIds[index] == color) {
              $('#vehicle-colors').append(
                `<option value="${colorIds[index]}" selected> ${
                  colorNames[index]
                } </option>`
              );
            } else {
              $('#vehicle-colors').append(
                '<option value="' +
                  colorIds[index] +
                  '">' +
                  colorNames[index] +
                  '</option>'
              );
            }
          }

          // Create body style select
          $('#vehicle-body').html('');
          var bodyNames = data.img[0].BodyStyle;

          for (var j = 0; j < bodyNames.length; j++) {
            if (bodyNames[j] === body) {
              $('#vehicle-body').append(
                '<option data-id="' +
                  j +
                  '" value ="' +
                  bodyNames[j] +
                  '" selected>' +
                  bodyNames[j] +
                  '</option>'
              );
            } else {
              $('#vehicle-body').append(
                '<option data-id="' +
                  j +
                  '" value ="' +
                  bodyNames[j] +
                  '" >' +
                  bodyNames[j] +
                  '</option>'
              );
            }
          }

          $('#see-on-vehicle-detail > a').attr('href', function replaceUrl(
            i,
            a
          ) {
            return a.replace(
              /(&body=)([0-9]+)?(&color=)([0-9]+)?/gi,
              '$1' +
                $('#vehicle-body')
                  .find(':selected')
                  .data('id') +
                '$3' +
                $('#vehicle-colors').val()
            );
          });
        } else {
          car
            .find('.img-wrapper')
            .addClass('no-fitment')
            .find('.error')
            .text(
              'We do not currently have an image of this wheel on your vehicle. Check back soon.'
            );
        }
      }
    );
  } catch (err) {
    car
      .find('.img-wrapper')
      .addClass('no-fitment')
      .find('.error')
      .text('Error Loading Image for Your Vehicle');
  }
}

// Set Dropdowns
function setDropdownHandlers(
  car,
  year,
  make,
  model,
  submodel,
  configid,
  option
) {
  // Bind the color select
  $('#vehicle-colors').change(function changeColor() {
    var body = $('#vehicle-body').val();
    var color = $('#vehicle-colors').val();
    var wheel = $('#see-on-vehicle-detail').attr('data-wheel');
    $('#see-on-vehicle-detail > a').attr('href', function replaceLink(i, a) {
      return a.replace(/(&color=)([0-9]+)?/gi, '$1' + color);
    });
    detailCarImage(
      car,
      year,
      make,
      model,
      submodel,
      wheel,
      color,
      body,
      configid,
      option
    );
  });
  // Bind the body select
  $('#vehicle-body').change(function changeBody() {
    var body = $('#vehicle-body').val();
    var color = 0;
    var wheel = $('#see-on-vehicle-detail').attr('data-wheel');
    $('#see-on-vehicle-detail > a').attr('href', function replaceLink(i, a) {
      return a.replace(
        /(&body=)([0-9]+)?(&color=)([0-9]+)?/gi,
        '$1' +
          $('#vehicle-body')
            .find(':selected')
            .data('id') +
          '$3'
      );
    });
    detailCarImage(
      car,
      year,
      make,
      model,
      submodel,
      wheel,
      color,
      body,
      configid,
      option
    );
  });
}

// Check if vehicle is set in session first
if ($('#see-on-vehicle-detail').length) {
  var car = $('#see-on-vehicle-detail');
  var year = car.data('year');
  var make = car.data('make');
  var model = car.data('model');
  var submodel = car.data('submodel');
  var wheel = car.attr('data-wheel');
  var configid = car.data('config');
  var option = car.data('option');
  if (typeof option === 'undefined') {
    option = '';
  }
  detailCarImage(
    car,
    year,
    make,
    model,
    submodel,
    wheel,
    0,
    '',
    configid,
    option
  );
  setDropdownHandlers(car, year, make, model, submodel, configid, option);
}

// text more less
if($('.product-detail').length) {
  $('.btn-overflow').each(function() {
    var btn = $(this),
      text = btn.parent().parent().find('.text-overflow'),
      h = text[0].scrollHeight;
    console.log(text);
    
    // compare height limit
    if(h > 80) {
      btn.addClass('less');
      btn.css('display', 'block');
    }
    
    // button function
    btn.click(function(e) {
      e.stopPropagation();
      text = $(this).parent().parent().find('.text-overflow');

      if ($(this).hasClass('less')) {
        $(this).removeClass('less');
        $(this).addClass('more');
        $(this).html('<i class="fas fa-minus-circle"></i> Read less');

        text.animate({'height': h});
      } else {
        $(this).addClass('less');
        $(this).removeClass('more');
        $(this).html('<i class="fas fa-plus-circle"></i> Read more');
        text.animate({'height': '80px'});
      }  
    });
    
  });
}

/* ==========================================================================
     CARTS AND POPUPS
     ========================================================================== */
// Add Accessory to cart
$('.accessory-open').on('click', function() {
  $('.wheelFlag').prop('value', 1);
  var frontWheel = $('.partnum_1').val();
  var rearWheel = $('.partnum_2').val();
  if (frontWheel == rearWheel) {
    $('.qty_2').attr('value', 4);
  }
  $('#cart-wheelForm').submit();
});

$('.wheelUpdate').on('click', function() {
  $('.wheelFlag').prop('value', 0);
});

$('#cart-wheelForm').on('submit', function(e) {
  if (
    $(this)
      .find('.wheelFlag')
      .prop('value') == 1
  ) {
    e.preventDefault();
    $.magnificPopup.open({
      items: {
        src: '#accessory-popup',
        type: 'inline'
      }
    });
  } else {
    return true;
  }
});

function updateCart() {
  $.each($('#cart-wheelForm').children('.item'), function(index) {
    var itemCount = index + 2;
    $.each($(this).children('input'), function() {
      $(this).prop('name', function(_, name) {
        return name.replace(new RegExp('x|\\d'), itemCount + 1);
      });
    });
  });
}

function updatePackage(itemNumber) {
  $.each(
    $('#package-Form[data-item="' + itemNumber + '"]').children('.item'),
    function(index) {
      var itemCount = index + 4;
      $.each($(this).children('input'), function() {
        $(this).prop('name', function(_, name) {
          return name.replace(new RegExp('x|\\d'), itemCount + 1);
        });
      });
    }
  );
}

$(':checkbox').on('click', function() {
  var copy = $(this)
    .siblings('.item')
    .clone();
  var copyItem = $(this).data('item');
  if ($(this).is(':checked')) {
    $('#cart-wheelForm').append(copy);
    updateCart();
  } else {
    $('#cart-wheelForm' + ' .' + copyItem).remove('.' + copyItem);
    updateCart();
  }
});

$('.accessoryCheck').on('click', function() {
  var copy = $(this)
    .siblings('.item')
    .clone();
  var copyItem = $(this).data('item');
  var itemNumber = $('#accessory-popup').data('item');
  if ($(this).is(':checked')) {
    $('#package-Form[data-item="' + itemNumber + '"]').append(copy);
    updatePackage(itemNumber);
  } else {
    $('#package-Form[data-item="' + itemNumber + '"]' + ' .' + copyItem).remove(
      '.' + copyItem
    );
    updatePackage(itemNumber);
  }
});

$('.packageContent').on('click', '.cancelBtn', function(e) {
  e.stopPropagation();
  $.magnificPopup.close();
});

$('.packageUpdate').on('click', function() {
  $(this)
    .siblings('.packageFlag')
    .prop('value', 0);
});

$('.packageContent').on('click', '.updatePackage', function(e) {
  $(this)
    .siblings('.packageFlag')
    .prop('value', 1);

  $('.wheelOne-desc').val($('.frontDesc').val());
  $('.wheelTwo-desc').val($('.rearDesc').val());

  $('.wheelOne-part').val($('.partnum_1').val());
  $('.wheelTwo-part').val($('.partnum_2').val());

  $('.wheelOne-qty').val($('.frontQty').val());
  $('.wheelTwo-qty').val($('.rearQty').val());

  $('.wheelOne-make').val($('.frontMake').val());
  $('.wheelTwo-make').val($('.rearMake').val());

  $('.wheelOne-price').val($('.frontPrice').val());
  $('.wheelTwo-price').val($('.rearPrice').val());

  var frontPrice = $(this)
    .parents('form')
    .find('.frontPackagePrice');
  var rearPrice = $(this)
    .parents('form')
    .find('.rearPackagePrice');
  $('.price_3').val(frontPrice.children('option:selected').data('price'));
  $('.price_4').val(rearPrice.children('option:selected').data('price'));
  if (frontPrice.val() == rearPrice.val()) {
    $('.qty_4').val('4');
  } else {
    $('.qty_4').val('2');
  }
  var frontWheelPart = $(this)
    .parents('form')
    .find('.wheelOne-part')
    .val();
  var rearWheelPart = $(this)
    .parents('form')
    .find('.wheelTwo-part')
    .val();
  if (frontWheelPart == rearWheelPart) {
    $('.qty_2').val('4');
  } else {
    $('.qty_2').val('2');
  }
  if (
    $(this)
      .siblings('.packageFlag')
      .prop('value') == 1
  ) {
    e.preventDefault();
    var formValue = $(this)
      .parents('form')
      .data('item');
    $('.packageUpdate').prop('for', 'form-submit-' + formValue);
    $('#accessory-popup').attr('data-item', formValue);
    $.magnificPopup.open({
      items: {
        src: '#accessory-popup',
        type: 'inline'
      }
    });
    return false;
  } else {
    return true;
  }
});

// Get Tire Packages
var tireSizeFront = '';
var tireSizeRear = '';

function getAjaxPackage(URL, funct) {
  var strDomainAP = '/ajax/' + URL + '&funct=' + funct;
  try {
    $.ajax({
      url: strDomainAP,
      success: function(data) {
        $('.packageContent').html(data);
        $.magnificPopup.open({
          items: {
            src: '#package-popup',
            type: 'inline'
          }
        });
      }
    });
  } catch (err) {
    window.location.reload();
  }
}

$('.package-open').on('click', function() {
  var intConfigID = $(this).data('configid');
  tireSizeFront = $('.partnum_1').data('tire');
  tireSizeRear = $('.partnum_1').data('rear');
  var url =
    'getTirePackage.cfm?tireSize=' +
    tireSizeFront +
    '&tireSizeRear=' +
    tireSizeRear +
    '&configID=' +
    intConfigID;
  getAjaxPackage(url, 'GetTirePackageQRY');
});

$('.frontWheel, .rearWheel').change(function() {
  var frontVal = $('.frontWheel')
    .find('option:selected')
    .attr('value');
  var rearVal = $('.rearWheel')
    .find('option:selected')
    .attr('value');
  if (frontVal !== '' && rearVal !== '') {
    $('.packageBtn').addClass('package-open');
  } else {
    $('.packageBtn').removeClass('package-open');
  }
});

$('.packageBtn').on('click', function(e) {
  var frontVal = $('.frontWheel')
    .find('option:selected')
    .attr('value');
  var rearVal = $('.rearWheel')
    .find('option:selected')
    .attr('value');
  if (frontVal === '' || rearVal === '') {
    alert('Please Select a front and rear wheel');
  } else {
    e.stopPropagation();
    $.magnificPopup.open({
      items: {
        src: '#package-popup',
        type: 'inline'
      }
    });
  }
});
// Match package select drop downs
$('.packageContent').on(
  'change',
  '.frontTirePackage, .rearTirePackage',
  function() {
    var firstSelect = '';
    var secondSelect = '';

    firstSelect = $('option:selected', this).attr('value');
    secondSelect = $(this)
      .closest('.drop')
      .siblings('.drop')
      .children('select')
      .children('option');
    secondSelect.each(function() {
      if ($(this).attr('value') == firstSelect) {
        $(this).prop('selected', true);

        $('.price_4').val($(this).data('price'));
      }

      var firstSelectVal = $(this)
        .find(':selected')
        .attr('value');
      var secondSelectVal = secondSelect.find(':selected').attr('value');
      if (firstSelectVal == secondSelectVal) {
        $('.qty_4').attr('value', 4);
      } else {
        $('.qty_4').attr('value', 2);
      }
    });

    $('.price_3').val($('option:selected', this).data('price'));
  }
);

// Set hidden price input
$('.frontWheel, .frontTire').on('change', function() {
  $('.price_1').attr(
    'value',
    $(this)
      .find(':selected')
      .data('price')
  );
  var firstSelect = $(this)
    .find(':selected')
    .attr('value');
  var secondSelect = $('.rearWheel, .rearTire')
    .find(':selected')
    .attr('value');
  if (firstSelect == secondSelect) {
    $('.qty_2').attr('value', 4);
  } else {
    $('.qty_2').attr('value', 2);
  }
});
$('.rearWheel, .rearTire').on('change', function() {
  $('.price_2').attr(
    'value',
    $(this)
      .find(':selected')
      .data('price')
  );
  var firstSelect = $('.frontWheel, .frontTire')
    .find(':selected')
    .attr('value');
  var secondSelect = $(this)
    .find(':selected')
    .attr('value');
  if (firstSelect == secondSelect) {
    $('.qty_2').attr('value', 4);
  } else {
    $('.qty_2').attr('value', 2);
  }
});

$('.tireCart-submit').on('click', function() {
  $('#cart-singleTireForm').submit();
});

/* ==========================================================================
     4.1  Home Page
     ========================================================================== */
/* MOTION UI - RANDOM TRANSITIONS /////////*/
if (Foundation.MediaQuery.atLeast('medium')) {
  $(window).scroll(function scrollPage() {
    $('.animate').each(function animateElem() {
      var $this = $(this);
      var transitions = [
        'slide-in-left',
        'slide-in-up',
        'slide-in-right',
        'fade-in',
        'hinge-in-from-right',
        'hinge-in-from-bottom',
        'hinge-in-from-left'
      ];
      var randomNum = Math.floor(Math.random() * (transitions.length - 1));
      if ($this.offset().top - $(window).scrollTop() - wh <= 0) {
        MotionUI.animateIn($this, transitions[randomNum]);
        $this.removeClass('animate');
      }
    });
  });
}

// front page slider
$('.hp-slider').slick({
    autoplay: true
  ,arrows: false
  ,adaptiveHeight:true
});



/* ==========================================================================
     4.2  Interior
     ========================================================================== */

// show more less list
var btn = $('.list-overflow-button'),
    displayLimit = 10;

btn.each(function(){
  let list = $(this).parent().parent().find('.list-overflow');
  let listItem = list.find('.guide-item');
  let current = 0;
  listItem.each(function(){
    if(current > displayLimit) {
      $(this).hide();
    } else {
      $(this).show();
    }
    current++
  });
});

btn.click(function(e) {
  console.log('pew pew');
  var list = $(this).parent().parent().find('.list-overflow'),
    listItem = listItem = list.find('.guide-item'),
    listTotal = list.find('.guide-item').length,
    items = $('.guide-item').length;
       //h = text[0].scrollHeight;
  e.stopPropagation();
  //text = $(this).parent().parent().find('.text-overflow');

  if ($(this).hasClass('less')) {
      $(this).removeClass('less');
      $(this).addClass('more');
      $(this).html('<i class="fas fa-minus-circle"></i> Show less');
      listItem.show();

  } else {
      $(this).addClass('less');
      $(this).removeClass('more');
      $(this).html('<i class="fas fa-plus-circle"></i> Show more');
      let current = 0;
      listItem.each(function(){
        if(current > displayLimit) {
          $(this).hide();
        }
        current++
      });
  }  
});

/* ==========================================================================
     4.3 Instruction Manuals / Guides
     ========================================================================== */


if($('#guides').length) {
  //console.log('looks like we made it...');
  let isSku = $('input[name="manual"]').val();
  $('.manual-lookup').click(function(){
    let lookup = $('input[name="manual"]').val();
    lookup = lookup.replaceAll('#','');
    lookup = lookup.replaceAll(' ','');
    var url = '/ajax/getManual.cfm?manual=' + lookup;
    $.get(url,function getGuide(data){
      $('.manual-results').html(data);
    });
    //console.log(lookup);
  });

  //console.log(isSku);
  if(isSku != '') {
    $('.manual-lookup').trigger('click');
  }
}
