if($('#wheel').length) {
  // recommended products
  $('#recommended-product-select').change(function(){
    let selectName = $(this).find(':selected').attr('data-name');
    let selectTitle = $(this).find(':selected').attr('data-title');
    let selectImage = $(this).find(':selected').attr('data-image');
    let selectSku = $(this).find(':selected').val();
    let selectPrice = $(this).find(':selected').attr('data-price');
    $('.recommended-product-name').html(selectTitle);
    $('.recommended-product-image img').attr('src',selectImage);
    $('#cart-wheelsForm-addtl input[name="sku"]').val(selectSku);
    $('#cart-wheelsForm-addtl input[name="name"]').val(selectName);
    $('#cart-wheelsForm-addtl input[name="price"]').val(selectPrice);
  });
}