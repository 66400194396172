// search
$('#search-product-form, #search-product-form-mobile').submit(function() {
  console.log('search conversion');
  let searched = $(this).find('input').val();
  fbq('track', 'Search',{search_string: searched});
});
/*
// add to cart
$('.add-to-cart').click(function() {
  console.log('add to cart conversion');
  fbq('track', 'AddToCart');
});

// initiate checkout
$('#cart-accessoryForm, #cart-wheelsForm, #cart-wheelsForm-addtl').submit(function() {
  console.log('initiate checkout conversion');
  let category = $(this).attr('id');
  fbq('track', 'InitiateCheckout',{content_category: category});
});
*/
// find location
$('#dealer-search').submit(function() {
  console.log('find locations conversion');
  fbq('track', 'FindLocation');
});