/*$('#article-gal-main-image').magnificPopup({
    delegate: 'a',
    type: 'image',
    arrows: true,
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1]
    }
  });

$('#article-gal-main-image').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        /// display current image count - .gal-detail-info-connt ///
        $('#currentSlide').text(nextSlide+1);
    }).slick({
    adaptiveHeight: false,
    arrows: true,
    asNavFor: '#article-gal-thumbs',
    fade: true,
    lazyLoad: 'ondemand'
  });

$('#article-gal-thumbs').slick({
    arrows: true,
    asNavFor: '#article-gal-main-image',
    focusOnSelect: true,
    infinite: false,
    slidesToShow: 5,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToScroll: 3,
          slidesToShow: 3
        }
      }
    ]
  });*/


$('#article-gal-thumbs').magnificPopup({
    delegate: 'a',
    type: 'image',
    arrows: true,
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1]
    }
});
