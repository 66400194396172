if($('#exp').length) {
  $('.exp-slider').slick({
    centerMode: true,
    centerPadding: '250px',
    slidesToShow: 1,
    nextArrow: '<button class="vehicles-next vehicles-arrow" aria-label="Next" type="button"><i class="fas fa-caret-right"></i></button>',
    prevArrow: '<button class="vehicles-prev vehicles-arrow" aria-label="Prev" type="button"><i class="fas fa-caret-left"></i></button>',
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '40px',
          slidesToShow: 1
        }
      }
    ]
  });
}