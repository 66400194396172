// header

/*var searchBtn = document.getElementsByClassName("header-mobile-search")[0];
var ymmBtn = document.getElementsByClassName("header-mobile-ymm")[0];

searchBtn.addEventListener("click", function() {
  console.log('searching... searching... searching...');
  var container = document.getElementsByClassName("header-mobile-search-content")[0];
  toggleContent(searchBtn,container);
});
ymmBtn.addEventListener("click", function() {
  console.log('fruit salad, yummy yummy!');
  var container = document.getElementsByClassName("header-mobile-ymm-content")[0];
  toggleContent(ymmBtn,container);
});

function toggleContent(btn, container) {
  btn.classList.toggle("content-open");
  if (container.style.maxHeight){
    container.style.maxHeight = null;
  } else {
    container.style.maxHeight = container.scrollHeight + "px";
  } 
}*/