if($('#promotion').length) {
  // recon pro page
  if($('.recon-pro').length) {
    $('.recon-pro-slider').magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1],
        dots: false
      }
    }).slick({
      centerMode: true,
      centerPadding: '10%',
      slidesToShow: 1,
      nextArrow: '<button class="recon-pro-next recon-pro-arrow" aria-label="Next" type="button"><i class="fas fa-caret-right"></i></button>',
      prevArrow: '<button class="recon-pro-prev recon-pro-arrow" aria-label="Prev" type="button"><i class="fas fa-caret-left"></i></button>',
      responsive: [
        {
          breakpoint: 640,
          settings: {
            arrows: false
          }
        }
      ]
    });

    $('.innerlock-slider').magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
        enabled: true,
        navigateByImgClick: true,
        preload: [0, 1],
        dots: false
      }
    }).slick({
      centerMode: true,
      centerPadding: '10%',
      slidesToShow: 5,
      nextArrow: '<button class="recon-pro-next recon-pro-arrow" aria-label="Next" type="button"><i class="fas fa-caret-right"></i></button>',
      prevArrow: '<button class="recon-pro-prev recon-pro-arrow" aria-label="Prev" type="button"><i class="fas fa-caret-left"></i></button>',
      responsive: [
        {
          breakpoint: 640,
          settings: {
            arrows: false,
            slidesToShow: 3,
          }
        }
      ]
    });
  }
}