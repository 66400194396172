$('.fitment-open').magnificPopup({
  items: {
    src: '#fitment-popup',
    type: 'inline'
  },
  callbacks: {
    close: function() {
      location.reload();
    }
  }
});

$('.checkFitment-open').magnificPopup({
  items: {
    src: '#checkFitment-popup',
    type: 'inline'
  }
});

$('.js-search-btn').magnificPopup({
  items: {
    src:
      '<div class="white text-center processing">' +
      '<div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>' +
      '</div>',
    type: 'inline',
    modal: true
  },
  callbacks: {
    open: function() {
      $('#search-form').submit();
    }
  }
});

$('.js-tireFitment').magnificPopup({
  items: {
    src: '#app-guide-tires-popup',
    type: 'inline'
  }
});

$('.js-accessoryFitment').magnificPopup({
  items: {
    src: '#app-guide-accessory-popup',
    type: 'inline'
  }
});

$('.js-fitmentCheck').magnificPopup({
  items: {
    src: '#app-guide-fitment-check-popup',
    type: 'inline'
  }
});

// Gallery Detail Quote Popup
$('.open-galleryDetail-quote-popup').magnificPopup({
  items: {
    src: '#galleryDetail-quote-popup',
    type: 'inline'
  }
});

// RFQ Popup
$('.open-quote-popup').magnificPopup({
  items: {
    src: '#quote-popup',
    type: 'inline'
  }
});

// Gallery View Popup
$('.wheel-gallery-photo').magnificPopup({
  type: 'image',
  gallery: {
    enabled: true,
    navigateByImgClick: true,
    preload: [0, 1]
  }
});

// Wheel Detail Quote Popup
$('.open-detail-quote-popup').magnificPopup({
  items: {
    src: '#detail-quote-popup',
    type: 'inline'
  }
});

// Tire Detail Quote Popup
$('.open-tireDetail-quote-popup').magnificPopup({
  items: {
    src: '#tireDetail-quote-popup',
    type: 'inline'
  }
});

// Accessory Detail Quote Popup
$('.open-accessoryDetail-quote-popup').magnificPopup({
  items: {
    src: '#accessoryDetail-quote-popup',
    type: 'inline'
  }
});

$('.cart-open').magnificPopup({
  items: {
    src: '#cart-popup',
    type: 'inline'
  }
});

$('a.video, #videos a, .popup-video').magnificPopup({
  disableOn: 700,
  type: 'iframe',
  mainClass: 'mfp-fade',
  removalDelay: 160,
  preloader: false,
  fixedContentPos: false,
  iframe: {
  markup: '<div class="mfp-iframe-scaler">'+
            '<div class="mfp-close"></div>'+
            '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>'+
          '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button

  patterns: {
    youtube: {
      index: 'youtube.com/', // String that detects type of video (in this case YouTube). Simply via url.indexOf(index).

      id: 'v=', // String that splits URL in a two parts, second part should be %id%
      // Or null - full URL will be returned
      // Or a function that should return %id%, for example:
      // id: function(url) { return 'parsed id'; }

      src: '//www.youtube.com/embed/%id%?autoplay=1&amp;rel=0&amp;controls=0&amp;showinfo=0' // URL that will be set as a source for iframe.
    },
    vimeo: {
      index: 'vimeo.com/',
      id: '/',
      src: '//player.vimeo.com/video/%id%?autoplay=1'
    },
    gmaps: {
      index: '//maps.google.',
      src: '%id%&output=embed'
    }

    // you may add here more sources

  },

  srcAction: 'iframe_src', // Templating object key. First part defines CSS selector, second attribute. "iframe_src" means: find "iframe" and set attribute "src".
}
});


// wheels upsell open
/*
$('.upsell-wheels-open').magnificPopup({
  items: {
    src: '#upsell-wheels-popup',
    type: 'inline'
  }
});
*/